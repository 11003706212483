* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	cursor: none !important;
}

html {
	scrollbar-color: #666 #201c29;
}

::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

::-webkit-scrollbar-thumb {
	background: #434343;
	border-radius: 16px;
	box-shadow: inset -2px -2px 2px rgb(0 0 0 / 25%);
}

::-webkit-scrollbar-track {
	background: linear-gradient(90deg, #434343, #434343 1px, #111 0, #111);
}

.vanta-canvas {
	height: 102.1% !important;
}

nav {
	display: flex !important;
}

.nav-index {
	z-index: 90 !important;
	max-width: 99vw;
}

.form-container {
	max-width: 99vw;
}

::-moz-selection,
::selection {
	background: gray;
}

.main-text {
	font-family: Bogart-Regular, sans-serif;
}

.sub-text {
	font-family: Bogart-Light, sans-serif;
}

.cursor {
	width: 40px;
	height: 40px;
	border: 2px solid #fefefe;
	border-radius: 100%;
	position: fixed;
	transform: translate(-50%, -50%);
	pointer-events: none;
	z-index: 9999;
	mix-blend-mode: difference;
	transition: all 150ms ease;
	transition-property: opacity, background-color, transform, mix-blend-mode;
}

.cursor-hidden {
	opacity: 0;
}

.cursor-clicked {
	transform: translate(-50%, -50%) scale(0.5);
	background-color: #fefefe;
}

.cursor-link-hovered {
	transform: translate(-50%, -50%) scale(1.8);
	background-color: #fefefe;
}

.project-tech-stack > * + * {
	margin-left: 1rem;
}

.project-image canvas,
.project-image img {
	border-radius: 0.5rem;
}

.grayscale-emoji {
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
}
